<template>
  <el-dialog
    :title="title"
    :visible.sync="show"
    class="choose-selectedPlan-checkCode"
    width="500px"
    :close-on-press-escape="false"
    :close-on-click-modal="false"
    :beforeClose="beforeClose"
    append-to-body
  >
    <div class="main flex">
      <div class="img">
        <QrcodeVue
          :value="value + `?xrjh=${ data && data.xrjh}&&xrdw=${  data && data.org &&data.org.name}&&enterType=1#/regulations`"
          :size="size"
          level="H"
        ></QrcodeVue>
      </div>
      <div class="title">{{data.jhmc}}报名二维码</div>
      <el-button type="primary" @click="download" size="small">下载二维码</el-button>
    </div>
  </el-dialog>
</template>

<script>
import QrcodeVue from 'qrcode.vue';
export default {
  // 查看二维码
  name: 'checkCode',
  props: {
    title: {
      tyle: String,
    },
  },
  components: { QrcodeVue },
  data() {
    return {
      show: false,
      value: 'https://jxssft.rmpsy.jxtongke.com/phone/',
      size: 250,
      data: {},
    };
  },
  methods: {
    beforeClose(done) {
      done();
      this.$emit('changeShow', false);
    },
    download() {
      let canvas = document.querySelector('canvas');
      let base64 = canvas.toDataURL();
      return this.downloadImg(base64, this.data.jhmc + '报名二维码');
    }, // 下载方法。
    /**
     *@param imgSrc 文件链接可以是一个dataURL 也可以是一个 blob 对象。
     *@param imgName  下载后的文件名字。
     */
    downloadImg(imgSrc, imgName) {
      let elem = document.createElement('a');
      elem.setAttribute('href', imgSrc);
      elem.setAttribute('download', imgName);
      document.body.appendChild(elem);
      elem.click();
      document.body.removeChild(elem);
    },

    toggle(show, data) {
      console.log(data);
      this.show = show;
      if (show && data) {
        this.data = data;
        console.log(`${this.value}?xrjh=${this.data.xrjh}&&xrdw=${this.data.org.name}#/regulations`);
      }
    },
  },
  mounted() {},
  created() {},
  computed: {},
  watch: {},
};
</script>

<style scoped lang="scss">
::v-deep .el-dialog__title {
  font-weight: bold;
}
.el-dialog {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .main {
    flex-direction: column;
    .img {
      width: 250px;
      height: 250px;
      img {
        width: 250px;
        height: 250px;
      }
    }
    .title {
      margin: 10px 0;
    }
  }
}
</style>
